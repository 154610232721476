/* eslint-disable jsx-a11y/no-distracting-elements */
import React, { useState, useEffect } from 'react';
import './App.css';
import backgroundVideo from './background-video.mp4';
import { TypeAnimation } from 'react-type-animation';

function App() {
  const [showContent, setShowContent] = useState(true);
  const [titleIndex, setTitleIndex] = useState(0);

  const handleEnterSite = () => {
    setShowContent(false);
  };

  useEffect(() => {
    let intervalId;
    const titles = ["BURNTROUTER.INFO", "ROUTER", "B U R N T R O U T E R . I N F O", "Router#1384"]

    if(!showContent) {
        intervalId = setInterval(() => {
            setTitleIndex((prevIndex) => (prevIndex + 1) % titles.length);
        }, 2000);
    }

    const handleContextMenu = (event) => {
      event.preventDefault();
    };

    document.addEventListener('contextmenu', handleContextMenu);

    return () => {
        clearInterval(intervalId);
        document.title = titles[titleIndex];
        document.removeEventListener('contextmenu', handleContextMenu);
    };
  }, [showContent, titleIndex]);

  return (
    <div className="app">
      {showContent ? (
        <div className="content">
          <p className="enter-button" onClick={handleEnterSite}>
            [enter site]
          </p>
        </div>
      ) : (
        <div className="video-wrapper">
          <video className="background-video" autoPlay loop volume={0.1} playsInline>
            <source src={backgroundVideo} type="video/mp4" />
          </video>
          <div className="centered-text">
            <h2>ROUTER</h2>
            <TypeAnimation
  sequence={[
    'IT Engineer',
    1000,
    'Does things in the Rust Community',
    1000,
    'Griddying in Fortnite',
    1000,
    'I stole this design from GlobalFall',
    1000,
  ]}
  speed={50}
  style={{ fontSize: '1em', fontFamily: 'inherit'}}
  repeat={Infinity}
/>
          </div>
          <Footer />
        </div>
      )}
    </div>
  );
}

function Footer() {
  return (
    <div className="footer">
      <marquee behavior="scroll" direction="left">
        Router#1384 | snailracing.co | github.com/burntrouter | router@burntrouter.info | i backdoored your router via dns 🦆
      </marquee>
    </div>
  );
}

export default App;
